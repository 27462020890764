import {connect} from "react-redux";
import Grid from "../../grid";
import Popup from "../../popup";
import React from "react";
import Loader from "../../../atoms/loader";
import Button from "../../../atoms/button";
import TextInput from "../../../atoms/text-input";
import ButtonGroup from "../../button-group";
import ActionButton from "../../../atoms/action-button";
import NumberWidget from "../../number-widget";
import {handleTokenErrors} from "../../../../state/modules/errors";
import {checkoutCart, checkoutMollieCart} from "../../../../state/modules/carts";
import {API_HOST, API_ROOT, http} from "../../../../util/http";

class CartCheckoutScreen extends React.Component {
    static defaultProps = {
        cart: null,
        createInvoice: false,
        onCancel: () => {},
        onSuccess: (method) => {},
        onFail: (message) => {},
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            method: null,
            active: null,
            
            received: {
                cash: 0
            },
            remaining: this.props.cart.total,
        }
    }
    
    chooseCash() {
        if (this.props.cart.total === 0) {
            this.payCash();
            return;
        }
        
        this.setState({
            method: 'cash',
            remaining: this.props.cart.total - this.state.received.cash,
        });
    }
    
    payCash() {
        if (this.state.remaining.toFixed(2) > 0) {
            this.setState({
                received: {
                    cash: this.props.cart.total - this.state.remaining,
                }
            });
            
            this.toggleLoading();
            
            return;
        }
        
        this.props.checkoutCart(this.props.cart.id, 'cash', this.state.createInvoice).then(() => {
            this.props.onSuccess();
        });
    }
    
    payCard() {
        this.props.checkoutCart(this.props.cart.id, 'card', this.state.createInvoice).then(() => {
            this.props.onSuccess();
        });
    }
    
    payMollie() {
        this.toggleLoading();
        
        this.setState({
            method: 'mollie',
        });
        
        this.props.checkoutMollieCart(this.props.cart.id, 'mollie', this.state.createInvoice, this.state.received.cash)
            .then(() => {
                const paymentCheck = setInterval(() => {
                    http
                        .request({url: API_HOST + API_ROOT + 'carts/' + this.props.cart.id + '/checkout-status'}, true)
                        .then(response => {
                            if (response.result === 0) {
                                return;
                            }
                            
                            if (response.result === 1) {
                                clearInterval(paymentCheck);
                                
                                this.props.onSuccess();
                                
                                return;
                            }
                            
                            if (response.result === 2) {
                                this.props.onFail('Payment cancelled');
                            }
                            
                            if (response.result === 3) {
                                this.props.onFail('Payment failed');
                            }
                            
                            if (response.result === 4) {
                                this.props.onFail('Payment expired');
                            }
                            
                            clearInterval(paymentCheck);
                        });
                }, 1000);
            })
            .catch(error => {
                handleTokenErrors(error);
                
                if (error.message === 'payment_failed') {
                    this.props.onFail('Payment failed');
                }
            })
            .finally(() => {
                this.toggleLoading();
            })
    }
    
    toggleLoading() {
        this.setState({
            method: null,
        });
    }
    
    handleCancel() {
        if (!this.state.method) {
            this.props.onCancel();
        }
        
        this.toggleLoading();
    }
    
    render() {
        let footer = (
            <ButtonGroup>
                <Button text={'Cancel'} modifiers={'alt'} onClick={this.handleCancel.bind(this)}/>
                {this.state.method === 'cash' && (
                    <Button text={'Pay'} onClick={event => {
                        this.payCash();
                    }}/>
                )}
            </ButtonGroup>
        );
        
        return (
            <Popup title={'Cart #' + this.props.cart.id} footer={footer} onClose={this.handleCancel.bind(this)}>
                <Grid>
                    <Grid modifiers={'padded'}>
                        <NumberWidget prefix={'€'} value={this.props.cart.total.toFixed(2)} title={'Order total'}/>
                        
                        {this.state.received.cash > 0 && (
                            <NumberWidget prefix={'€'} value={this.state.received.cash.toFixed(2)} title={'Received'} modifiers={'success'}/>
                        )}
                        
                        {this.state.method === 'cash' && (
                            <NumberWidget prefix={'€'} value={this.state.remaining?.toFixed(2)} title={this.state.remaining > 0 ? 'Remaining' : 'Change'} modifiers={this.state.remaining > 0 ? 'warning' : 'success'} />
                        )}
                    </Grid>
                </Grid>
                
                {!this.state.method && (
                    <Grid cols={'1fr 1fr'}>
                        <ActionButton modifiers={'xl'} text={'Pay Mollie'} onClick={this.payMollie.bind(this)}/>
                        <ActionButton modifiers={'xl'} text={'Pay card'} onClick={this.payCard.bind(this)}/>
                        <ActionButton modifiers={'xl'} text={'Pay cash'} onClick={this.chooseCash.bind(this)}/>
                    </Grid>
                )}
                
                {this.state.method === 'cash' && (
                    <div className="content-loader">
                        <TextInput
                            modifiers={'big'}
                            name="received"
                            label="Received"
                            type="number"
                            default={(this.state.received.cash ? '' : this.props.cart.total)}
                            showRequiredIndicator={true}
                            focus={true}
                            onChange={(value) => {
                                value = parseFloat(value.toString().replace(',', '.'));
                                
                                if (isNaN(value)) {
                                    value = 0
                                }
                                
                                this.setState({
                                    remaining: this.props.cart.total - this.state.received.cash - value,
                                });
                            }}
                            onFocus={(event) => {
                                let value = event.target.value ?? null;
                                
                                value = parseFloat(value.toString().replace(',', '.'));
                                
                                if (isNaN(value)) {
                                    value = 0
                                }
                                
                                this.setState({
                                    remaining: this.props.cart.total - this.state.received.cash - value,
                                });
                            }}
                        />
                    </div>
                )}
                
                {this.state.method === 'mollie' && (
                    <div className="content-loader">
                        <Loader isLoading={true} size="large"/>
                    </div>
                )}
            </Popup>
        );
    }
}

const mapStateToProps = (state) => ({
    carts: state.carts.carts,
    lists: state.resources.lists,
});

const mapDispatchToProps = {
    checkoutCart: checkoutCart,
    checkoutMollieCart: checkoutMollieCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartCheckoutScreen);